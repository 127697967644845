// Generated by Framer (dbba614)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import IcMenu20px from "./pvGr1s8Yx";
const IcMenu20pxFonts = getFonts(IcMenu20px);

const enabledGestures = {pGKTgXyS_: {hover: true, pressed: true}, yWwPOkww_: {hover: true, pressed: true}};

const cycleOrder = ["yWwPOkww_", "pGKTgXyS_"];

const serializationHash = "framer-un7RE"

const variantClassNames = {pGKTgXyS_: "framer-v-m7bvjn", yWwPOkww_: "framer-v-hfl9ll"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {close: "pGKTgXyS_", menu: "yWwPOkww_"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, BtoUucIyY: click ?? props.BtoUucIyY, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yWwPOkww_"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BtoUucIyY, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yWwPOkww_", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1y73n2g = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (BtoUucIyY) {const res = await BtoUucIyY(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-hfl9ll", className, classNames)} data-framer-name={"menu"} data-highlight layoutDependency={layoutDependency} layoutId={"yWwPOkww_"} onTap={onTap1y73n2g} ref={ref ?? ref1} style={{backgroundColor: "var(--token-52b9f1ec-8a5c-4a68-9b06-4560bba78883, rgb(255, 255, 255))", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}} variants={{"pGKTgXyS_-hover": {backgroundColor: "var(--token-79762126-b16b-4fb9-9dc0-b2321cf95106, rgb(240, 240, 240))"}, "pGKTgXyS_-pressed": {backgroundColor: "var(--token-7301e9d3-b304-413d-9ade-5c8a4fdcd6d3, rgb(221, 220, 219))"}, "yWwPOkww_-hover": {backgroundColor: "var(--token-79762126-b16b-4fb9-9dc0-b2321cf95106, rgb(240, 240, 240))"}, "yWwPOkww_-pressed": {backgroundColor: "var(--token-7301e9d3-b304-413d-9ade-5c8a4fdcd6d3, rgb(221, 220, 219))"}}} {...addPropertyOverrides({"pGKTgXyS_-hover": {"data-framer-name": undefined}, "pGKTgXyS_-pressed": {"data-framer-name": undefined}, "yWwPOkww_-hover": {"data-framer-name": undefined}, "yWwPOkww_-pressed": {"data-framer-name": undefined}, pGKTgXyS_: {"data-framer-name": "close"}}, baseVariant, gestureVariant)}><ComponentViewportProvider height={20} width={"20px"} y={((componentViewport?.y || 0) + (10 + ((((componentViewport?.height || 40) - 20) - 20) / 2)))}><motion.div className={"framer-zw8k7t-container"} layoutDependency={layoutDependency} layoutId={"C6pGik4sA-container"}><IcMenu20px height={"100%"} id={"C6pGik4sA"} layoutId={"C6pGik4sA"} style={{height: "100%", width: "100%"}} variant={"ka44FrJww"} width={"100%"} {...addPropertyOverrides({pGKTgXyS_: {variant: "LMioE4id2"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-un7RE.framer-29x3r5, .framer-un7RE .framer-29x3r5 { display: block; }", ".framer-un7RE.framer-hfl9ll { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 10px; position: relative; width: min-content; }", ".framer-un7RE .framer-zw8k7t-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-un7RE.framer-hfl9ll { gap: 0px; } .framer-un7RE.framer-hfl9ll > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-un7RE.framer-hfl9ll > :first-child { margin-left: 0px; } .framer-un7RE.framer-hfl9ll > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"pGKTgXyS_":{"layout":["auto","auto"]},"nYGvM066c":{"layout":["auto","auto"]},"ufGDYAbQe":{"layout":["auto","auto"]},"I4wLxAdor":{"layout":["auto","auto"]},"pyqSsYNpO":{"layout":["auto","auto"]}}}
 * @framerVariables {"BtoUucIyY":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKqo4aOqLr: React.ComponentType<Props> = withCSS(Component, css, "framer-un7RE") as typeof Component;
export default FramerKqo4aOqLr;

FramerKqo4aOqLr.displayName = "button-ic-menu";

FramerKqo4aOqLr.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerKqo4aOqLr, {variant: {options: ["yWwPOkww_", "pGKTgXyS_"], optionTitles: ["menu", "close"], title: "Variant", type: ControlType.Enum}, BtoUucIyY: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerKqo4aOqLr, [{explicitInter: true, fonts: []}, ...IcMenu20pxFonts], {supportsExplicitInterCodegen: true})